import React, { useState, useEffect } from 'react';

const Timer = () => {
    const start = new Date('2024-08-12T19:46:00.000Z');
    const [cur, setTime] = useState(new Date());
  
    function formattedTime() {
      const elapsed_sec = Math.floor(cur/1000) % 60
      const elapsed_min = (Math.floor(cur/6e4 - start/6e4)) % 60
      const elapsed_hrs = (Math.floor(cur/3.6e6 - start/3.6e6)) % 24
      const elapsed_days = Math.floor(cur/8.64e7 - start/8.64e7)
  
      const days = `${elapsed_days} days`
      const hrs = `${elapsed_hrs} ${elapsed_hrs != 1 ? 'hours' : 'hour'}`
      const mins = `${elapsed_min} ${elapsed_min != 1 ? 'minutes' : 'minute'}`
      const secs = `${elapsed_sec} ${elapsed_sec != 1 ? 'seconds' : 'second'}`
  
      return `${days} ${hrs} ${mins} ${secs}`;
    };
  
    useEffect(() => {
      while (true) {
        const interval = setInterval(() => {
          setTime(new Date());
        }, 10);
        return () => clearInterval(interval);
      }
    }, [cur]);
  
    return (
      <div className='timer'>
        <p>We've been together for:</p>
        <p>{formattedTime()}</p>
      </div>
    );
  }

export default Timer;