import logo from './rose.svg'
import './App.css';
import Timer from './Timer'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-message">
          <p>Happy monthsary Lovey! I love you so much ❤️</p>
          <Timer/>
        </div>
      </header>
      <body>
      </body>
    </div>
  );
}

export default App;
